$(document).ready(function () {

  var in_sortable = false;

  $("#drag-drop-area").sortable({

    cursor: 'move',
    revert: 300, // animation duration in ms
    placeholder: 'ui-sortable-placeholder',
    tolerance: 'pointer',
    zIndex: 1,

    activate: function (event, ui) {
      //remove placeholder when dropping a block
      $('li').remove('li.placeholder');
    },

    stop: function (event, ui) {
      // instructions what to do when dropped a block
      $("#guidance").text("Click pen to edit. Drag outside to remove.");
    },

  }).disableSelection();
});


$("#containment-wrapper li").draggable({
  connectToSortable: '#drag-drop-area', //this line does the magic
  cursor: 'move',
  revert: 'invalid',
  helper: 'clone',
  opacity: 0.8
});

// Add help text below
$("#containment-wrapper li").droppable({

  // Add an element when droppable items starts to drag
  activate: function (event, ui) {
    $("#guidance").text("Drop inside droppable area to use, drag outside to remove.");
  },

});


//console.log("default.html loaded");


//$('#yes').on('click', function(evt) {
$(document).on('click', '#yes', function () {
  console.log('yes');
  $('.building-block').remove();
  $(this).parent().remove();
});

$('#cancel').on('click', function () {
  console.log('cancel');
  //$(this).parent().fadeOut('slow');
  $(this).parent().remove();
});
