/*
    HISTORIANA 
    =====----

    ALSO PARTLY DEFINED IN DEFAULT.html

*/

// GLOBALS (Tssk)

//console.log("Loading Historiana..");


$(document).foundation();


var tl_filters = new Array();


/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #1 - September 4, 2014
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path[, domain]])
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

/* COOKIE MANAGEMENT */

var docCookies = {
    getItem: function (sKey) {
        if (!sKey) {
            return null;
        }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
            return false;
        }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
            case Number:
                sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                break;
            case String:
                sExpires = "; expires=" + vEnd;
                break;
            case Date:
                sExpires = "; expires=" + vEnd.toUTCString();
                break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
    },
    removeItem: function (sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) {
            return false;
        }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    },
    hasItem: function (sKey) {
        if (!sKey) {
            return false;
        }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    },
    keys: function () {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
            aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
        }
        return aKeys;
    }
};

// 
function check_login() {
    var me = null;
    me = docCookies.getItem('username');
    console.log("checklogin me: " + me);

    if (me) {
        $(".logged-in").show();
        $(".not-logged-in").hide();


    } else {
        console.log("** not logged in **");
        $(".logged-in").hide();
        $(".not-logged-in").show();
    }
}


$(".tabs-title").click(function () {
    $(this).addClass("selected");
});

var pages = {
    "/": "homepage",
    "/HC-home.html": "historical-content",
    "/TL-home.html": "teaching-learning",
    "/AB-home.html": "activity-builder",
    "/MH-activities.html": "my-historiana",
    "/MH-profile.html": "my-historiana",
    "/MH-sources.html": "my-historiana",
    "/MH-resources.html": "my-historiana",
    "/MH-activitylog.html": "my-historiana",
    "/BB-text.html": "bb-text",
    "/BB-question.html": "bb-question",
    "/BB-embed.html": "bb-embed",
    "/BB-sequencing.html": "bb-sequencing",
    "/BB-analysing.html": "bb-analysing"
};




$("#do-login").on('click', () => {

    var login = $("#login").val();
    var pass = $("#password").val();
    alert("login: " + login + " pass: " + pass);
    return false;
});

check_login();

$('.menu li').removeClass('selected');
$('.' + pages[document.location.pathname]).addClass('selected');
$('body').addClass('is-' + pages[document.location.pathname]);
console.log(">> activate?: ", pages[document.location.pathname]);

// setup tabs
$("#tab-browse").on('click', () => {
    $('.tabs-panel').each(function () {
        if (this.id != 'browse-content') {
            $(this).hide();
        }
    });
    $('#browse-content').toggle();
});

$("#tab-search").on('click', function () {
    $('.tabs-panel').each(function () {
        if (this.id != 'search-content') {
            $(this).hide();
        }
    });
    $('#search-content').toggle();
});

$("#tab-directions").on('click', function () {
    $('.tabs-panel').each(function () {
        if (this.id != 'directions-content') {
            $(this).hide();
        }
    });
    $('#directions-content').toggle();
});
// $("#tab-featured").on( 'click', function() {
//     $('.tabs-panel').each( function () {  if (this.id!='featured-content') { $(this).hide(); } });
//     $('#featured-content').toggle();


$('#do_login').on('click', function (e) {
    console.log("====");
    username = $('#login').val();
    passwd = $('#passwd').val();
    console.log("l:" + username + " p: " + passwd);
    $('#login_modal').foundation('close');
    docCookies.setItem('username', username);
    check_login();

});

$('#logout_button').on('click', function (e) {
    docCookies.removeItem('username');
    console.log("logout " + me);
    check_login();
});



// CODE FROM http://isotope.metafizzy.co/layout-modes/masonry.html/
// USED IN ACTIVITY BUILDER, MY HISTORIANA, BROWSE

/**
$(window).load(function () {

    console.log("window load isotope load");
    var $container = $('#card-holder');
    // initialize isotope
    $container.isotope({
        layoutMode: 'masonry',
        itemSelector: '.item',
        percentPosition: false,
        masonry: {
                //columnWidth: 192,
                fitWidth: true
            },

    });

    

    // filter items when filter link is clicked
    $('#filters a').click(function () {
        var selector = $(this).attr('data-filter');
        $container.isotope({
            filter: selector
        });
        return false;
    });

    // set selected menu items
    var $optionSets = $('.inline-list'),
        $optionLinks = $optionSets.find('a');

    $optionLinks.click(function () {
        var $this = $(this);
        // don't proceed if already selected
        if ($this.hasClass('selected')) {
            return false;
        }
        var $optionSet = $this.parents('.inline-list');
        $optionSet.find('.selected').removeClass('selected');
        $this.addClass('selected');
    });

});*/



// Foundation.Motion.animateIn('#content', 'hinge-in-from-top');
// Foundation.Motion.animateIn('.slidein', 'slide-in-right');


/*
 * Replace all SVG images with inline SVG
 */
jQuery('img.svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');

});



//hide layer with the AB in ActivityBuilder
//$('#AB-panel').hide();



/* old stuff which was commented already */






// change background-color on focus
//$(document).ready( function() {
//    
//    $("#content-body").on('mouseover touchstart', function() {
//        //console.log("focus in");
//        $("#content-header").addClass('make-grey');
//        $("#content-body").removeClass('make-grey');
//    });
//
//    $("#content-header").on('mouseover touchstart', function() {
//        //console.log("focus out");
//        $("#content-header").removeClass('make-grey');
//        $("#content-body").addClass('make-grey');
//    });
//    
//
//});


// $('.input-group-field').focus(function()
// {
//     $(this).animate({ width: '+=100' }, 'slow');
// }).blur(function()
// {
//     $(this).animate({ width: '-=100' }, 'slow');
// });






// $('#do_login').on('click', function (e) {
//  console.log("====");
//  username = $('#login').val();
//  passwd = $('#passwd').val();
//  console.log( "l:" + username + " p: "+passwd);
//  $('#login_modal').foundation('close');



//  //var elem = $('#elem-to-animate');
//  //Foundation.Motion.animateIn(elem, animationClass [, callback]);
//  //Foundation.Motion.animateOut(elem, animationClass [, callback]);
//  //$("#modalTitle").addClass('shake');
//  //$('#login_modal').animationClass('shake');
//  //$('#login_modal').one().addClass('shake').delay(1000).fadeIn(0, function() { $(this).removeClass('shake'); });
//  //$('#login_modal').addClass('shake');
//  //$('#login_modal').addClass('is-animating');

// });



// setup filters in TL-home.html
//  $('.filter_click').on('click', function f(e) { 
//    me = this;
//    $(me).toggleClass("button is-active");
//
//    active = $('.filter_click').each( function (k,v) {
//      deze = this;
//      if ( $(deze).hasClass('is-active')) {
//
//        $("div.column."+deze.id).show();
//
//      } else {
//        $("div.column."+deze.id).hide();
//
//      }
//      $('#blockgrid-sort').foundation(); 
//      //$('#blockgrid-sort').addClass('button');
//    });
//  });
//
//  $('.filter_click#all').on('click', function f(e) {
//    $("div.column").show();
//  });
